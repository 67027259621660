export function TopoBg() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 268 572" class="size-full">
			<g fill="currentColor" fill-rule="evenodd" clip-rule="evenodd">
				<path d="M198 0c9.9 11.3 23.3 20 38.5 24.9 10.3 3.2 21 4.8 31.6 5.5v1c-10.7-.8-21.5-2.3-31.8-5.6-15.8-5-29.5-14-39.7-25.8h1.3ZM175.6 0l.8 1.2C191.6 22.4 214 37 241.3 43.8a256 256 0 0 0 26.8 5v.9c-8.9-1.2-18-2.7-27-5a112 112 0 0 1-65.5-43L174.4 0h1.2ZM12 568l15.1-5c24.2-7.7 51.6-17.6 70.4-38 14.1-15.3 17.9-35.8 21.5-55.7a404.6 404.6 0 0 1 14.5-57.1c1.5-4.4 2.8-8.9 4-13.3 2.9-9.4 5.7-19.2 9.7-28.2 6-13.5 17.7-17.2 27.2-19 12-2.2 24.7-4.9 36.6-11 18.6-9.7 32.1-26.3 42.5-52.4 5.3-13.3 10-27.1 14.6-40.7v12a678.8 678.8 0 0 1-11 30.2c-10.6 26.6-25.1 44.4-44.4 54.3a133 133 0 0 1-37.6 11.4c-13 2.4-20.2 7.4-24.4 16.8-3.9 8.8-6.7 18.4-9.4 27.7l-4.1 13.4c-4.6 14-8.2 27.1-11.2 40.3-1.2 5.3-2.2 10.9-3.2 16.3-3.7 20.4-7.6 41.5-22.5 57.7-19.4 21-47.3 31.1-72 39a1005 1005 0 0 0-16 5.3H0l12-4Z" />
				<path d="M268.1 71c-2-.5-4-1-6.1-1.7-21.7-6-44-12.2-63.5-24.3a171.3 171.3 0 0 1-46-45h1.3c13.3 17.7 27.1 33 45.2 44.2 19.4 12 41.7 18.2 63.2 24.2l6 1.7v1ZM42.5 23.1A101 101 0 0 1 51.2 0h1.1c-3.9 7.5-7 15.3-8.8 23.4-5.7 23.9-2.9 49.4 9.2 82.7 10.7 29.5 22.9 55.7 36.2 78 11 18.2 24.6 33 38.9 48.6L139 245l11.5 13.3c5.3 6.1 10.7 12.4 16.3 18.4l3.2 3.7c7.1 7.8 15.2 16.8 24.7 20.1 2.6 1 5.2 1.4 7.9 1.4 19 0 34.4-23.7 40.2-34a380.7 380.7 0 0 0 25.2-58v2.7c-6.9 19-14.4 38.2-24.4 55.9-7.3 12.8-22.3 34.4-41 34.4-2.8 0-5.5-.5-8.2-1.4-9.8-3.5-17.9-12.5-25-20.5l-3.3-3.6c-5.6-6-11-12.4-16.3-18.5l-11.5-13.2-11.3-12.4c-14.4-15.7-28-30.5-39-48.8a451.4 451.4 0 0 1-36.3-78.2c-12.2-33.5-15-59.1-9.3-83.3ZM268.1 102.5l-2-2.4c-9.2-9-21-14.6-32.6-19.9l-4.7-2.2A280.3 280.3 0 0 1 160 34.7c-4-3.6-7.8-7.6-12-11.7-8.2-8.5-16.8-17.2-26.5-23h1.9c9.2 5.8 17.4 14.1 25.4 22.3 4 4.2 8 8.1 11.9 11.6a279.4 279.4 0 0 0 73.1 45.4 110.2 110.2 0 0 1 34.2 21.7v1.5Z" />
				<path d="M57 28A58.4 58.4 0 0 1 76.7 0h1.7A56.9 56.9 0 0 0 58 28.3c-6.4 19.2-5.5 41.6 2.9 68.4 7.8 25 20 51.4 35.5 76.5 13.6 22.2 30.8 39.4 52.4 58.4l3 2.6c13.8 12.3 32.7 29 51.2 29 2.9 0 5.7-.4 8.4-1.3 18.4-5.8 29-24.4 37.6-43.5a361.1 361.1 0 0 0 19.2-56.8v3.7a349.5 349.5 0 0 1-18.3 53.5c-8.8 19.3-19.5 38-38.2 44-2.8 1-5.7 1.4-8.7 1.4-18.8 0-37.9-16.9-51.8-29.2l-3-2.7c-21.7-19-39-36.3-52.6-58.6A349 349 0 0 1 59.9 97c-8.5-27-9.4-49.6-2.9-69Z" />
				<path d="M101.4 26c11 0 24.1 5.8 39 17.5a730 730 0 0 0 71.8 48.7c8.7 5.3 17.8 10.8 24.5 18.4a54.8 54.8 0 0 1 12.2 34.6v9c-4 25.3-10.2 51-28.5 68-6.5 6-14.2 9.1-22.8 9.1-15.9 0-32.1-10.2-43-18C118 187.4 90.2 145.6 76 95.5 71 78 66 52.8 79.5 36.7c6-7.2 13.3-10.8 21.9-10.8Zm96.2 206.3c8.9 0 16.8-3.2 23.5-9.4 18.4-17.2 24.7-43.1 28.7-68.6.1-3.4.2-6.4 0-9.2-.2-10-2.7-24.1-12.3-35.1-7-7.8-16-13.3-24.8-18.7l-4-2.4A730 730 0 0 1 141 42.8C126 30.9 112.6 25 101.4 25c-8.9 0-16.5 3.7-22.7 11-13.7 16.5-8.6 42-3.6 59.8 14.3 50.3 42.3 92.3 79 118.3 10.9 7.8 27.4 18.2 43.5 18.2Z" />
				<path d="M225.7 163.8c-1.9 11.4-7.3 31.2-22.6 37.8a33.9 33.9 0 0 1-13.5 2.8c-12.8 0-27.4-6.6-43.4-19.7-26-21.2-46.1-57.7-51.4-93-1.6-11.1-1.7-25.4 7.3-30.8 2.8-1.6 6-2.5 9.6-2.5 12.3 0 26.8 9.7 36.4 16.1a537.5 537.5 0 0 0 20 12.7c11.2 7 22.8 14 33.3 22.4 7.5 6 13.8 13.2 19.7 22.6 5.3 8.4 5 19 4.6 29.2v2.4Zm-36 44.5c5.2 0 10.2-1 15-3.1 17-7.4 22.8-28.8 24.8-41v-2.7c.4-10.3.7-22-5.2-31.4a90.8 90.8 0 0 0-20.5-23.5C193 98.1 181.4 90.9 170 83.9l-16.8-10.6-3-2c-10-6.7-25-16.7-38.5-16.7-4.2 0-8.1 1-11.5 3-8.7 5.2-11.8 16.8-9.2 34.6 5.4 36.3 26.1 73.7 52.7 95.5 16.8 13.6 32.2 20.6 46 20.6Z" />
				<path d="M137.7 102c9.4 0 22.2 6.6 27 9.1 11.5 5.9 38 22.4 34.4 48.5-3.4 12.7-12.4 15.4-19.3 15.4-6.6 0-14.5-2.5-21.5-6.9-9.2-5.7-17-14-22.7-24-3-5-12.2-22.9-8-35.6 1.3-4.3 4.7-6.4 10-6.4Zm42 74c7.3 0 16.7-2.8 20.4-16.2 3.6-26.8-23.2-43.6-35-49.6-4.8-2.5-17.8-9.1-27.4-9.1-5.8 0-9.5 2.4-11 7-4.3 13.2 5.1 31.3 8 36.6a68.3 68.3 0 0 0 23 24.2c7.3 4.5 15.3 7 22 7ZM140 554.7c12.5-6 19.4-12.7 21.9-21.3 2.9-10.2 0-21-2.6-31.4l-.9-3.4c-8-32.1-6.3-58.2 5.3-79.7 11.1-20.9 30.4-33.8 49-46.2 19-12.7 38.5-25.8 49.5-47.3 2.3-4.4 4.2-9 6-13.7v2.7c-1.6 4-3.3 7.8-5.2 11.5-11 21.7-30.8 34.8-49.8 47.6-18.5 12.4-37.6 25.2-48.7 45.8-11.4 21.4-13.1 47.2-5.1 79l.8 3.4c2.6 10.1 5.6 21.6 2.6 32-3.3 11.6-14.3 18-22.3 21.9a263.7 263.7 0 0 1-46 16.4h-3.8c16.7-4.3 33.5-9.7 49.4-17.3Z" />
				<path d="M194.7 428.7c9.6-20.1 27.7-33 45.3-45.5A232 232 0 0 0 268 361v1.3c-8.4 8.1-18 15-27.6 21.7-17.4 12.4-35.5 25.2-45 45-19.4 41.3 23 79.2 51 104.3l3.3 3c6.4 5.8 12.7 11.8 18.3 18.5v1.5c-5.8-7-12.3-13.3-18.9-19.2l-3.4-3c-28.2-25.3-70.9-63.6-51-105.4Z" />
				<path d="M222.4 446.5c1-17.5 12-30.3 21.7-39.5 7.2-7 15.3-13.5 24-19.4v1.2a181 181 0 0 0-23.4 18.9c-9.4 9-20.4 21.7-21.3 38.9a58.2 58.2 0 0 0 12.6 37.2c9 12 20.7 21.8 32.1 31.1v1.3a190.5 190.5 0 0 1-32.8-31.9 59.1 59.1 0 0 1-12.9-37.8Z" />
			</g>
		</svg>
	);
}
